<template>
	<errorContainer :error="erreur">
		<transition name="slide">
			<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
				<div class="form">
					<!-- <b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(17, 'Document package') }}
							</h1>
						</b-col>
					</b-row> -->
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-card-text>
									<div class="container-field">
										<b-row>
											<b-col sm="12" md="6" lg="6" xl="6">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input
														type="text"
														v-model="filter"
														:placeholder="FormMSG(65, 'Type to search')"
													/><!-- @change="handleChangeFilter" -->
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon('Search')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon('X')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																@click="handleChangeFilter('')"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
											<b-col sm="12" md="3" lg="3" xl="3" class="pt-1">
												<b-form-checkbox
													v-model="hideArchivedDocument"
													size="lg"
													class="pj-cb"
													:value="true"
													:unchecked-value="false"
													@change="handleChangeShowArchivedDocument"
												>
													{{ FormMSG(126, 'Hide archived package') }}
												</b-form-checkbox>
											</b-col>
											<b-col sm="12" md="3" lg="3" xl="3">
												<b-button variant="outline-primary" block @click="handleClickAddNew">
													{{ FormMSG(20, 'Add new') }}
												</b-button>
											</b-col>
										</b-row>
										<b-row class="mt-3">
											<b-col>
												<CardListBuilder v-if="$screen.width < 992" :items="documentPackages" :fields="packageFields">
													<template slot="actions" slot-scope="data">
														<div class="mt-3">
															<b-button variant="outline-primary" class="mr-2" @click="handleClickDuplicate(data.item)">
																<component :is="getLucideIcon('Copy')" :size="16" />
															</b-button>
															<b-button variant="outline-primary" class="mr-2" @click="redirectToDetail(data.item)">
																<component :is="getLucideIcon('Eye')" :size="16" />
															</b-button>
															<b-button variant="outline-primary" class="mr-2" @click="handleClickRemoveDocument(data.item)">
																<component :is="getLucideIcon('Trash2')" :size="16" />
															</b-button>
														</div>
													</template>
												</CardListBuilder>
												<b-table
													v-if="$screen.width >= 992"
													selected-variant="primary"
													hover
													selectable
													select-mode="single"
													responsive="sm"
													ref="documentPackageTable"
													sticky-header="700px"
													:items="documentPackages"
													style="text-align: left"
													:fields="packageFields"
													:filter="filter"
													bordered
													small
													head-variant="dark"
													:empty-text="FormMSG(158, 'No document package found')"
													show-empty
													tbody-tr-class="p-2"
													@row-clicked="handleClickEdit($event)"
												>
													<template #cell(actions)="data">
														<div class="d-flex justify-content-center" style="min-width: 60px">
															<b-dropdown
																id="dropdown-offer-action"
																no-caret
																dropleft
																boundary="window"
																class="btn-transparent"
																variant="none"
																size="sm"
															>
																<template #button-content>
																	<component :is="getLucideIcon('MoreVertical')" :size="16" />
																</template>
																<b-dropdown-item @click.stop="handleClickDuplicate(data.item)">
																	<span class="text-color-rhapsody-in-blue">
																		<component :is="getLucideIcon('Copy')" :size="16" />&nbsp;{{ FormMSG(9, 'Duplicate') }}
																	</span>
																</b-dropdown-item>
																<b-dropdown-item
																	:disabled="data.item.childDocList.length === 0"
																	@click.stop="handlePreviewDocument(data.item.childDocList)"
																>
																	<span class="text-color-rhapsody-in-blue">
																		<component :is="getLucideIcon('Eye')" :size="16" />&nbsp;{{ FormMSG(15, 'View') }}
																	</span>
																</b-dropdown-item>
																<b-dropdown-item
																	:disabled="!(data.item.documentNextVersionId === 0 && data.item.archived !== 0)"
																	@click.stop="handleClickCreateNewVersion(data.item)"
																>
																	<span
																		:class="{
																			'text-color-rhapsody-in-blue':
																				data.item.documentNextVersionId === 0 && data.item.archived !== 0
																		}"
																	>
																		<component :is="getLucideIcon('PlusCircle')" :size="16" />&nbsp;{{
																			FormMSG(136, 'Create new version')
																		}}
																	</span>
																</b-dropdown-item>
																<b-dropdown-divider></b-dropdown-divider>
																<b-dropdown-item
																	v-if="data.item.archived >= 1"
																	:disabled="data.item.archived >= 2"
																	@click.stop="handleArchiveDocument(data.item)"
																>
																	<span
																		:class="{
																			'text-color-burning-tomato': data.item.archived < 2
																		}"
																	>
																		<component :is="getLucideIcon('Archive')" :size="16" />&nbsp;{{
																			FormMSG(110, 'Archive')
																		}}
																	</span>
																</b-dropdown-item>
																<b-dropdown-item v-else @click.stop="handleClickRemoveDocument(data.item)">
																	<span
																		:class="{
																			'text-color-burning-tomato': data.item.archived === 0
																		}"
																	>
																		<component :is="getLucideIcon('Trash2')" :size="16" />&nbsp;{{ FormMSG(11, 'Delete') }}
																	</span>
																</b-dropdown-item>
															</b-dropdown>
														</div>
													</template>
												</b-table>
											</b-col>
										</b-row>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { newDocument, getDocumentList, duplicateDocument, deleteDocument, updateDocument } from '@/modules/document-package/cruds/document.crud';
import globalMixin from '@/mixins/global.mixin';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import * as icons from 'lucide-vue';

export default {
	name: 'DocumentPackageIndex',
	mixins: [languageMessages, globalMixin],
	components: {},
	data() {
		return {
			erreur: {},
			documentPackages: [],
			documentPackagesOriginal: [],
			filter: '',
			hideArchivedDocument: false
		};
	},
	computed: {
		packageFields() {
			let fields = [
				{
					key: 'name',
					label: this.FormMSG(5, 'Name'),
					sortable: true
				},
				{
					key: 'version',
					label: this.FormMSG(121, 'Version'),
					sortable: true,
					formatter: (v) => {
						return `V${v}`;
					}
				},
				{
					key: 'documentList',
					label: this.FormMSG(6, 'Document list'),
					sortable: true,
					formatter: (value, key, item) => {
						return this.formatDocumentChild(item);
					}
				}
			];

			if (this.$screen.width >= 992) {
				fields = [
					...fields,
					{
						key: 'actions',
						label: this.FormMSG(111, 'Actions'),
						sortable: false,
						class: 'text-center'
					}
				];
			}

			return fields;
		}
	},
	async created() {
		await this.getDocumentList();
	},
	methods: {
		async handleArchiveDocument(item) {
			const docId = item.id;
			let doc = {
				...item,
				archived: 3
			};
			delete doc.id;
			let result = await updateDocument(docId, doc);
			if (result) {
				await this.getDocumentList();
			}
		},
		async handleClickCreateNewVersion(item) {
			const result = await duplicateDocument(item.id, item.version + 1);

			this.$router.push({
				path: `/document-package/${result.id}`
			});
		},
		getClassTr(item) {
			return 'p-2 tbody-tr-frozen';
		},
		handleClickEdit(payload) {
			this.handleRowClicked(payload);
		},
		async handleChangeShowArchivedDocument(payload) {
			this.hideArchivedDocument = payload;
			await this.getDocumentList();
		},
		handlePreviewDocument(childDocList) {
			const list = childDocList.map((doc) => ({
				...doc,
				// src: `${process.env.VUE_APP_GQL}/images/${doc.xid}`,
				src: `${process.env.VUE_APP_GQL}/images/${doc.xid}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
				ext: getFileExtension(doc.fileName)
			}));

			console.log({ list });

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					showSignFileButton: false,
					showStatusFileTag: false,
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		handleRowClicked(payload) {
			this.redirectToDetail(payload);
		},
		handleChangeFilter(payload) {
			let filter = payload.trim().toLowerCase();
			if (filter === '') this.documentPackages = this.documentPackagesOriginal;
			let arr = this.documentPackagesOriginal.filter(function (s) {
				const searchFromName = s.name.toLowerCase().indexOf(filter);
				let resultSearchFromChild = false;
				for (let i = 0; i < s.childDocList.length; i++) {
					const element = s.childDocList[i];
					if (element.name.toLowerCase().indexOf(filter) > -1) {
						resultSearchFromChild = true;
						break;
					}
				}

				return searchFromName > -1 || resultSearchFromChild;
			});

			this.filter = filter;
			this.documentPackages = arr;
		},
		async handleClickRemoveDocument(doc) {
			try {
				const action = async () => {
					await deleteDocument(doc.id).catch((e) => console.log(e));

					this.documentPackages = this.documentPackages.filter((dP) => dP.id !== doc.id);
					this.documentPackagesOriginal = this.documentPackagesOriginal.filter((dPO) => dPO.id !== doc.id);

					this.createToastForMobile(this.FormMSG(58, 'Success'), this.FormMSG(70, 'Document deleted successfully'), '', 'success');
				};

				this.confirmModal(action, this.FormMSG(72, 'Are you sure ?'));
			} catch (error) {
				this.createToastForMobile(this.FormMSG(60, 'Error'), this.FormMSG(71, 'Error deleting document package'), '', 'error');
			}
		},
		async handleClickDuplicate(doc) {
			try {
				await duplicateDocument(doc.id);

				this.createToastForMobile(this.FormMSG(58, 'Success'), this.FormMSG(59, 'Document duplicated successfully'), '', 'success');

				await this.getDocumentList();
			} catch (error) {
				this.createToastForMobile(this.FormMSG(60, 'Error'), this.FormMSG(61, 'Error while duplicating document'), '', 'error');
			}
		},
		async getDocumentList() {
			const response = await getDocumentList({
				documentType: 11,
				hideArchivedDocument: this.hideArchivedDocument,
				useStatus: false
			});
			this.documentPackages = response;
			this.documentPackagesOriginal = response;
		},
		async handleClickAddNew() {
			const newDoc = await newDocument({
				name: this.FormMSG(45, 'NEW DOCUMENT PACKAGE'),
				type: 11,
				availableOnline: true
			});

			this.redirectToDetail(newDoc);
		},
		redirectToDetail(item) {
			this.$router.push('/document-package/' + item.id);
		},
		formatDocumentChild(item) {
			if (item.childDocList.length > 0) {
				const childs = item.childDocList;
				let result = '';
				childs.forEach((child, index) => {
					if (index > 0 && index < childs.length) {
						result += ' / ';
					}
					result += child.name;
				});

				return result;
			} else {
				return '-';
			}
		},
		getLucideIcon(name) {
			return icons[name];
		}
	}
};
</script>
