var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c("b-card-text", [
                              _c(
                                "div",
                                { staticClass: "container-field" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "6",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _vm.$screen.width >= 992
                                            ? _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        65,
                                                        "Type to search"
                                                      ),
                                                    },
                                                    model: {
                                                      value: _vm.filter,
                                                      callback: function ($$v) {
                                                        _vm.filter = $$v
                                                      },
                                                      expression: "filter",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "btn-search",
                                                        },
                                                        [
                                                          _vm.filter.length ===
                                                          0
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  "Search"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                _vm.getLucideIcon(
                                                                  "X"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleChangeFilter(
                                                                          ""
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pt-1",
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb",
                                              attrs: {
                                                size: "lg",
                                                value: true,
                                                "unchecked-value": false,
                                              },
                                              on: {
                                                change:
                                                  _vm.handleChangeShowArchivedDocument,
                                              },
                                              model: {
                                                value: _vm.hideArchivedDocument,
                                                callback: function ($$v) {
                                                  _vm.hideArchivedDocument = $$v
                                                },
                                                expression:
                                                  "hideArchivedDocument",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      126,
                                                      "Hide archived package"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "outline-primary",
                                                block: "",
                                              },
                                              on: {
                                                click: _vm.handleClickAddNew,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(20, "Add new")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _vm.$screen.width < 992
                                            ? _c("CardListBuilder", {
                                                attrs: {
                                                  items: _vm.documentPackages,
                                                  fields: _vm.packageFields,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "actions",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickDuplicate(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Copy"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 16,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.redirectToDetail(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Eye"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 16,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickRemoveDocument(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Trash2"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 16,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  802627583
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm.$screen.width >= 992
                                            ? _c("b-table", {
                                                ref: "documentPackageTable",
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                attrs: {
                                                  "selected-variant": "primary",
                                                  hover: "",
                                                  selectable: "",
                                                  "select-mode": "single",
                                                  responsive: "sm",
                                                  "sticky-header": "700px",
                                                  items: _vm.documentPackages,
                                                  fields: _vm.packageFields,
                                                  filter: _vm.filter,
                                                  bordered: "",
                                                  small: "",
                                                  "head-variant": "dark",
                                                  "empty-text": _vm.FormMSG(
                                                    158,
                                                    "No document package found"
                                                  ),
                                                  "show-empty": "",
                                                  "tbody-tr-class": "p-2",
                                                },
                                                on: {
                                                  "row-clicked": function (
                                                    $event
                                                  ) {
                                                    return _vm.handleClickEdit(
                                                      $event
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "cell(actions)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-center",
                                                              staticStyle: {
                                                                "min-width":
                                                                  "60px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-dropdown",
                                                                {
                                                                  staticClass:
                                                                    "btn-transparent",
                                                                  attrs: {
                                                                    id: "dropdown-offer-action",
                                                                    "no-caret":
                                                                      "",
                                                                    dropleft:
                                                                      "",
                                                                    boundary:
                                                                      "window",
                                                                    variant:
                                                                      "none",
                                                                    size: "sm",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "button-content",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "MoreVertical"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handleClickDuplicate(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-color-rhapsody-in-blue",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Copy"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  9,
                                                                                  "Duplicate"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          data
                                                                            .item
                                                                            .childDocList
                                                                            .length ===
                                                                          0,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handlePreviewDocument(
                                                                              data
                                                                                .item
                                                                                .childDocList
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-color-rhapsody-in-blue",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Eye"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  15,
                                                                                  "View"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !(
                                                                            data
                                                                              .item
                                                                              .documentNextVersionId ===
                                                                              0 &&
                                                                            data
                                                                              .item
                                                                              .archived !==
                                                                              0
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handleClickCreateNewVersion(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "text-color-rhapsody-in-blue":
                                                                                data
                                                                                  .item
                                                                                  .documentNextVersionId ===
                                                                                  0 &&
                                                                                data
                                                                                  .item
                                                                                  .archived !==
                                                                                  0,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "PlusCircle"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  136,
                                                                                  "Create new version"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "b-dropdown-divider"
                                                                  ),
                                                                  data.item
                                                                    .archived >=
                                                                  1
                                                                    ? _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                data
                                                                                  .item
                                                                                  .archived >=
                                                                                2,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.handleArchiveDocument(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                {
                                                                                  "text-color-burning-tomato":
                                                                                    data
                                                                                      .item
                                                                                      .archived <
                                                                                    2,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Archive"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      110,
                                                                                      "Archive"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.handleClickRemoveDocument(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                {
                                                                                  "text-color-burning-tomato":
                                                                                    data
                                                                                      .item
                                                                                      .archived ===
                                                                                    0,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Trash2"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      11,
                                                                                      "Delete"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2672454850
                                                ),
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }